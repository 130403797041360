<template>
	<div style="height:100%;">
		<div>{{text}}</div>
		<mdl-alert type="info" effect="light">info</mdl-alert>
		<mdl-alert type="info" effect="dark">info</mdl-alert>
		<mdl-alert type="success" effect="light">success</mdl-alert>
		<mdl-alert type="success" effect="dark">success</mdl-alert>
		<mdl-alert type="warning" effect="light">warning</mdl-alert>
		<mdl-alert type="warning" effect="dark">warning</mdl-alert>
		<mdl-alert type="danger" effect="light">danger</mdl-alert>
		<mdl-alert type="danger" effect="dark">danger</mdl-alert>
		<mdl-alert type="text" effect="light">text</mdl-alert>
		<mdl-alert type="text" effect="dark">text</mdl-alert>
	</div>
</template>
<script>
export default {
	data () {
		return {
			text: "hello world.",
			// nameMapping: $utils.getNameMapping()
		}
	},
	created () {

		if (this.$route.params.uid) {
		} else {
			this.text = Ls.get("USER_INFO").uname;
		}
	}
};
</script>