var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticStyle: { height: "100%" } },
    [
      _c("div", [_vm._v(_vm._s(_vm.text))]),
      _c("mdl-alert", { attrs: { type: "info", effect: "light" } }, [
        _vm._v("info"),
      ]),
      _c("mdl-alert", { attrs: { type: "info", effect: "dark" } }, [
        _vm._v("info"),
      ]),
      _c("mdl-alert", { attrs: { type: "success", effect: "light" } }, [
        _vm._v("success"),
      ]),
      _c("mdl-alert", { attrs: { type: "success", effect: "dark" } }, [
        _vm._v("success"),
      ]),
      _c("mdl-alert", { attrs: { type: "warning", effect: "light" } }, [
        _vm._v("warning"),
      ]),
      _c("mdl-alert", { attrs: { type: "warning", effect: "dark" } }, [
        _vm._v("warning"),
      ]),
      _c("mdl-alert", { attrs: { type: "danger", effect: "light" } }, [
        _vm._v("danger"),
      ]),
      _c("mdl-alert", { attrs: { type: "danger", effect: "dark" } }, [
        _vm._v("danger"),
      ]),
      _c("mdl-alert", { attrs: { type: "text", effect: "light" } }, [
        _vm._v("text"),
      ]),
      _c("mdl-alert", { attrs: { type: "text", effect: "dark" } }, [
        _vm._v("text"),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }